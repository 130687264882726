import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pawel/Projekty/wyspiewana-tradycja/src/layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Licencje`}</h1>
    <p>{`Wszystkie reprezentacje cyfrowe utworów znajdujących się na stronie zespolyspiewacze.pl:`}</p>
    <ul>
      <li parentName="ul">{`zdjęcia,`}</li>
      <li parentName="ul">{`pliki z nutami,`}</li>
      <li parentName="ul">{`pliki muzyczne,`}</li>
      <li parentName="ul">{`pliki dźwiękowe,`}</li>
      <li parentName="ul">{`pliki ze scenariuszami lekcji`}</li>
    </ul>
    <p>{`są objęte prawami autorskimi i są udostępniane na licencji `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://creativecommons.org/licenses/by-nc-sa/4.0/deed.pl"
        }}>{`Uznanie autorstwa-Użycie niekomercyjne-Na tych samych warunkach 4.0 Międzynarodowe`}</a></strong>{` (CC BY-NC-SA 4.0).`}</p>
    <p>{`W razie pytań prosimy o kontakt: promocja@gok.psary.pl`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      